package es.cinfo.tiivii.user.screen

sealed class ScreenModel {
    sealed class ViewModel {
        enum class Screen {
            LOGIN, LEGAL, HOME
        }
        data class KeycloakParams(
            val confUri: String,
            val baseUri: String,
            val realm: String,
            val clientId: String
        )
    }

    internal class Model {
        internal sealed class Screen {
            data class KeycloakParams(
                val confUri: String,
                val baseUri: String,
                val realm: String,
                val clientId: String
            ) {
                fun toViewModel(): ViewModel.KeycloakParams {
                    return ViewModel.KeycloakParams(
                        confUri = confUri,
                        baseUri = baseUri,
                        realm = realm,
                        clientId = clientId
                    )
                }
            }

            data class AppLinks(
                val android: String,
                val ios: String
            )

            data class Auth(
                val accessToken: String,
                val refreshToken: String
            )

            abstract val keycloakParams: KeycloakParams
            abstract val styles: es.cinfo.tiivii.core.layout.model.layoutconfig.Model.LayoutConfig.StyleConfig
            abstract val appLinks: AppLinks?
            abstract val auth: Auth?

            data class Login(
                override val keycloakParams: KeycloakParams,
                override val styles: es.cinfo.tiivii.core.layout.model.layoutconfig.Model.LayoutConfig.StyleConfig,
                override val appLinks: AppLinks?,
                override val auth: Auth? = null
            ) : Screen()

            data class Legal(
                override val keycloakParams: KeycloakParams,
                override val styles: es.cinfo.tiivii.core.layout.model.layoutconfig.Model.LayoutConfig.StyleConfig,
                override val appLinks: AppLinks?,
                override val auth: Auth?
            ) : Screen()

            data class Home(
                override val keycloakParams: KeycloakParams,
                override val styles: es.cinfo.tiivii.core.layout.model.layoutconfig.Model.LayoutConfig.StyleConfig,
                override val appLinks: AppLinks?,
                override val auth: Auth?
            ) : Screen()

            fun toViewModel(): ViewModel.Screen {
                return when (this) {
                    is Login -> ViewModel.Screen.LOGIN
                    is Legal -> ViewModel.Screen.LEGAL
                    is Home -> ViewModel.Screen.HOME
                }
            }
        }
    }

}