package es.cinfo.tiivii.core.content.model

import es.cinfo.tiivii.core.content.model.ApiResponse.Content.Data.Companion.MAX_SCORE_VALUE
import es.cinfo.tiivii.core.content.model.ApiResponse.Content.Data.Companion.MIN_SCORE_VALUE
import es.cinfo.tiivii.core.content.model.WidgetModel.ApiResponse.Widget.Data
import es.cinfo.tiivii.core.content.model.WidgetModel.ViewModel.WidgetAction.*
import es.cinfo.tiivii.core.date.DateService
import es.cinfo.tiivii.core.image.ImageModule
import es.cinfo.tiivii.core.layout.LayoutApi
import es.cinfo.tiivii.core.layout.model.layoutconfig.parseAlphaHexOrNull
import es.cinfo.tiivii.core.modules.bookmark.model.BookmarkModel
import es.cinfo.tiivii.core.modules.rating.RatingModel
import es.cinfo.tiivii.core.translation.TranslationModel
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonTransformingSerializer
import org.kodein.di.instance
import kotlin.js.JsName
import kotlin.math.max
import kotlin.math.min
import es.cinfo.tiivii.core.image.ApiResponse.Image as ImageApiResponse
import es.cinfo.tiivii.core.image.Model.Image as ImageModel
import es.cinfo.tiivii.core.image.Model.Image.Type as ImageType

sealed class WidgetModel {
    sealed class ViewModel {

        /**
         * Represents a [Widget] content load
         * @param loadState indicates the widget loading status
         * @param type indicates the type of the widget
         * @param id indicates the id of the widget
         * @param widget represents the [Widget] that has been loaded (null if the widget has not been loaded yet)
         */
        data class WidgetContentLoad(
            val loadState: LoadingModel.ViewModel.LoadState,
            val type: WidgetType,
            val id: Int,
            val widget: Widget?,
        )

        /**
         * Represents the [Widget] type
         */
        enum class WidgetType {
            BANNER, BANNER_V2, WIDGET_PROGRESS, WIDGET_PROGRESS_V2, FEATURED, FEATURED_V2, BANNER_LARGE, DIRECTO_V2, LIVE, LIVE_2, LIVE_VERTICAL, FILTERS, POSTER, POSTER_V2, CIRCULAR, NEWS, GRID_V2;
        }

        companion object {
            const val FEATURED_ROTATE_TIME_SEC = 5
        }

        /**
         * Represents an action to be made when a given widget is used
         * @see DETAIL
         * @see PLAY
         * @see FULL_LIST
         */
        enum class WidgetAction {
            /**
             * The detail is screen should be shown
             */
            DETAIL,

            /**
             * The player should be shown
             */
            PLAY,

            /**
             * A screen with all the related widget contents should be shown
             */
            FULL_LIST
        }

        /**
         * Widget model for the UI
         */
        sealed class Widget {
            abstract val id: Int
            abstract val title: String
            abstract val subtitle: String?
            abstract val action: WidgetAction
            abstract val backgroundColor: String?
            abstract val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?
            abstract val textColor: String?
            abstract val hasMoreContents: Boolean

            /**
             * Widget model representing a single widget with a list of children contents
             */
            sealed class ContentWidget : Widget() {
                abstract val content: List<Data.Content>

                /**
                 * Widget model representing a list of big images
                 */
                data class Banner(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                ) : ContentWidget()

                data class BannerV2(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                ) : ContentWidget()

                data class GridV2(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                ) : ContentWidget()

                /**
                 * Same as [Banner] but bigger
                 */
                data class BannerLarge(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                ) : ContentWidget()

                /**
                 * Widget model representing a list of circular images
                 */
                data class Circular(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                ) : ContentWidget()

                /**
                 * Widget model representing a banner with visualization progress indication
                 */
                data class Progress(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                ) : ContentWidget()

                /**
                 * Widget model representing a banner with visualization progress indication
                 */
                 data class ProgressV2(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                 ) : ContentWidget()

                /**
                 * Widget model representing an important element
                 */
                data class Featured(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?,
                    val description: String?,
                    val rotateTimeSec: Int = FEATURED_ROTATE_TIME_SEC
                ) : ContentWidget()


                /**
                 * Widget model representing an important element
                 */
                data class FeaturedV2(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?,
                    val description: String?,
                    val rotateTimeSec: Int = FEATURED_ROTATE_TIME_SEC
                ) : ContentWidget()


                /**
                * Widget model representing a list of directo content
                */
                data class DirectoV2(
                   override val id: Int,
                   override val action: WidgetAction,
                   override val content: List<Data.Content>,
                   override val hasMoreContents: Boolean,
                   override val title: String,
                   override val subtitle: String?,
                   override val backgroundColor: String?,
                   override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                   override val textColor: String?
                ) : ContentWidget()
                /**

                 * Widget model representing a list of live content
                 */
                data class Live(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                ) : ContentWidget()

                /**
                 * Widget model representing a list of live content (other style)
                 */
                data class Live2(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                ) : ContentWidget()

                /**
                 * Widget model representing a list of live content in a vertical format
                 */
                data class LiveVertical(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                ) : ContentWidget()


                /**
                 * Widget model representing a list of poster images
                 */
                data class Poster(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                ) : ContentWidget()

                /**
                * Widget model representing a list of poster images
                */
                data class PosterV2(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                ) : ContentWidget()

                /**
                 * Widget model representing a list of news
                 */
                data class News(
                    override val id: Int,
                    override val action: WidgetAction,
                    override val content: List<Data.Content>,
                    override val hasMoreContents: Boolean,
                    override val title: String,
                    override val subtitle: String?,
                    override val backgroundColor: String?,
                    override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    override val textColor: String?
                ) : ContentWidget()
            }

            /**
             * Widget model representing a parent widget with children widgets
             */
            data class GroupWidget(
                override val id: Int,
                override val title: String,
                override val subtitle: String?,
                override val action: WidgetAction,
                override val backgroundColor: String?,
                override val backgroundImage: es.cinfo.tiivii.core.image.ViewModel.Image?,
                override val textColor: String?,
                override val hasMoreContents: Boolean,
                val widgets: List<Data.Widget>,
            ) : Widget()

            sealed class Data {

                /**
                 * Model representation of contents associated to a [GroupWidget]
                 */
                data class Content(
                    val id: Int,
                    val title: String,
                    val subtitle: String?,
                    val description: String?,
                    val category: String,
                    val subcategory: String?,
                    val publishDate: String?,
                    val tags: List<String>,
                    val background: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    val banner: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    val poster: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    val type: ContentTypeModel.ViewModel.ContentType,
                    val progress: Int? = null,
                    val canPlay: Boolean,
                    val canFav: Boolean,
                    var isFav: Boolean,
                    val score: Float,
                    val startTimeMs: Long?
                ) : Data() {

                    @JsName("secondsSinceStart")
                    fun secondsSinceStart(): Long? {
                        return if (startTimeMs != null) {
                            val dateService: DateService by diContainer.instance()
                            val timeNowMs = dateService.currentEpochMillis()
                            val timeSinceMs = timeNowMs - startTimeMs
                            timeSinceMs / 1000
                        } else {
                            null
                        }
                    }

                }

                /**
                 * Model representation of the widget children of a [GroupWidget]
                 */
                data class Widget(
                    val id: Int,
                    val title: String,
                    val image: es.cinfo.tiivii.core.image.ViewModel.Image?,
                    val color: String?,
                    val textColor: String?
                ) : Data()
            }

        }
    }
    internal sealed class Model {
        data class WidgetContentApiLoad(
            val type: WidgetType,
            val widget: Widget,
            val count: Int,
            val page: Int,
            val limit: Int,
            val sort: es.cinfo.tiivii.core.sorting.SortModel.Model.Sort,
        )

        data class WidgetListContentApiLoad(
            val widgetList: List<WidgetContentApiLoad>
        )

        data class WidgetContentLoad(
            val loadState: LoadingModel.Model.LoadState,
            val type: WidgetType,
            val id: Int,
            val widget: Widget?,
        ) {
            fun toViewModel(): ViewModel.WidgetContentLoad {
                return ViewModel.WidgetContentLoad(
                    loadState = loadState.toViewModel(),
                    type = type.toViewModel(),
                    id = id,
                    widget = widget?.toViewModel()
                )
            }
        }
        
        enum class WidgetAction {
            DETAIL, PLAY, FULL_LIST;

            fun toViewModel(): ViewModel.WidgetAction {
                return when (this) {
                    DETAIL -> ViewModel.WidgetAction.DETAIL
                    PLAY -> ViewModel.WidgetAction.PLAY
                    FULL_LIST -> ViewModel.WidgetAction.FULL_LIST
                }
            }

            companion object {
                fun parse(value: String): WidgetAction {
                    return when (value) {
                        "detail" -> DETAIL
                        "player" -> PLAY
                        "fulllist" -> FULL_LIST
                        else -> DETAIL
                    }
                }
            }
        }

        enum class WidgetType {
            BANNER, BANNER_V2, WIDGET_PROGRESS, WIDGET_PROGRESS_V2, FEATURED, FEATURED_V2, BANNER_LARGE, DIRECTO_V2, LIVE, LIVE_2, LIVE_VERTICAL, FILTERS, POSTER, POSTER_V2, CIRCULAR, NEWS, GRID_V2;

            companion object {
                fun parse(value: String): WidgetType {
                    return when (value) {
                        "circular" -> CIRCULAR
                        "banner" -> BANNER
                        "banner-v2" -> BANNER_V2
                        "grid-v2" -> GRID_V2
                        "widget_progress" -> WIDGET_PROGRESS
                        "widget_progress-v2" -> WIDGET_PROGRESS_V2
                        "featured" -> FEATURED
                        "featured-v2" -> FEATURED_V2
                        "banner_large" -> BANNER_LARGE
                        "directo-v2" -> DIRECTO_V2
                        "live" -> LIVE
                        "live-2" -> LIVE_2
                        "live-vertical" -> LIVE_VERTICAL
                        "filter-list" -> FILTERS
                        "poster" -> POSTER
                        "poster-v2" -> POSTER_V2
                        "news" -> NEWS
                        else -> BANNER
                    }
                }
            }

            fun toViewModel(): ViewModel.WidgetType {
                return when (this) {
                    BANNER -> ViewModel.WidgetType.BANNER
                    BANNER_V2 -> ViewModel.WidgetType.BANNER_V2
                    GRID_V2 -> ViewModel.WidgetType.GRID_V2
                    WIDGET_PROGRESS -> ViewModel.WidgetType.WIDGET_PROGRESS
                    WIDGET_PROGRESS_V2 -> ViewModel.WidgetType.WIDGET_PROGRESS_V2
                    FEATURED -> ViewModel.WidgetType.FEATURED
                    FEATURED_V2 -> ViewModel.WidgetType.FEATURED_V2
                    BANNER_LARGE -> ViewModel.WidgetType.BANNER_LARGE
                    DIRECTO_V2 -> ViewModel.WidgetType.DIRECTO_V2
                    LIVE -> ViewModel.WidgetType.LIVE
                    LIVE_VERTICAL -> ViewModel.WidgetType.LIVE_VERTICAL
                    FILTERS -> ViewModel.WidgetType.FILTERS
                    POSTER -> ViewModel.WidgetType.POSTER
                    POSTER_V2 -> ViewModel.WidgetType.POSTER_V2
                    CIRCULAR -> ViewModel.WidgetType.CIRCULAR
                    NEWS -> ViewModel.WidgetType.NEWS
                    LIVE_2 -> ViewModel.WidgetType.LIVE_2
                }
            }
        }

        sealed class Widget {
            abstract val id: Int
            abstract val type: WidgetType
            abstract val title: String
            abstract val subtitle: String?
            abstract val description: String?
            abstract val trigger: WidgetAction
            abstract val backgroundColor: String?
            abstract val backgroundImage: ImageModel?
            abstract val textColor: String?
            abstract val totalContents: Int
            abstract fun isEmpty(): Boolean

            sealed class ContentWidget : Widget() {
                abstract var content: List<Data.Content>

                override fun isEmpty(): Boolean = content.isEmpty()

                data class Banner(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.BANNER,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class BannerV2(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.BANNER_V2,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class GridV2(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.GRID_V2,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class BannerLarge(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.BANNER_LARGE,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class Circular(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.CIRCULAR,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class Progress(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.WIDGET_PROGRESS,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class ProgressV2(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.WIDGET_PROGRESS_V2,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class Featured(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.FEATURED,
                    override var content: List<Data.Content>,
                    val rotateTime: Int,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class FeaturedV2(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.FEATURED_V2,
                    override var content: List<Data.Content>,
                    val rotateTime: Int,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class DirectoV2(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.DIRECTO_V2,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class Live(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.LIVE,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class Live2(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.LIVE_2,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class LiveVertical(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.LIVE_VERTICAL,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class Poster(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.POSTER,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class PosterV2(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.POSTER_V2,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

                data class News(
                    override val id: Int,
                    override val type: WidgetType = WidgetType.NEWS,
                    override var content: List<Data.Content>,
                    override val title: String,
                    override val subtitle: String?,
                    override val description: String?,
                    override val trigger: WidgetAction,
                    override val backgroundColor: String?,
                    override val backgroundImage: ImageModel?,
                    override val textColor: String?,
                    override val totalContents: Int
                ) : ContentWidget()

            }

            data class GroupWidget(
                override val id: Int,
                override val type: WidgetType = WidgetType.FILTERS,
                val widgets: List<Data.Widget>,
                override val title: String,
                override val subtitle: String?,
                override val description: String?,
                override val trigger: WidgetAction,
                override val backgroundColor: String?,
                override val backgroundImage: ImageModel?,
                override val textColor: String?,
                override val totalContents: Int
            ) : Widget() {
                override fun isEmpty(): Boolean = widgets.isEmpty()
            }

            sealed class Data {

                data class Content(
                    val id: Int,
                    val title: String,
                    val subtitle: String?,
                    val description: String?,
                    val type: ContentTypeModel.Model.ContentType,
                    val category: String,
                    val subcategory: String?,
                    val tags: List<String>,
                    val episode: Int?,
                    val rating: String,
                    val score: Float,
                    val publishDate: String?,
                    val background: ImageModel?,
                    val banner: ImageModel?,
                    val poster: ImageModel?,
                    val bookmarks: List<BookmarkModel.Model.Bookmark> = emptyList(),
                    val votes: Int,
                    var canPlay: Boolean? = null,
                    var canFav: Boolean? = null,
                    var isFav: Boolean? = null,
                    var startTimeMs: Long?,
                ) : Data() {

                    fun toViewModel(): ViewModel.Widget.Data.Content {
                        val bookmark = BookmarkModel.Model.Bookmark.findLatestVideoPositionViewed(id, bookmarks)
                        val progress = bookmark?.let {
                            if (bookmark.latestPositionSec == 0 || bookmark.durationSec == 0) {
                                0
                            } else {
                                bookmark.latestPositionSec * 100 / bookmark.durationSec
                            }
                        } ?: 0
                        return ViewModel.Widget.Data.Content(
                            id = id,
                            title = title,
                            subtitle = subtitle,
                            description = description,
                            category = category,
                            subcategory = subcategory,
                            publishDate = publishDate,
                            tags = tags,
                            background = background?.toViewModel(),
                            banner = banner?.toViewModel(),
                            poster = poster?.toViewModel(),
                            type = type.toViewModel(),
                            progress = progress,
                            canPlay = canPlay ?: false,
                            canFav = canFav ?: false,
                            isFav = isFav ?: false,
                            score = score,
                            startTimeMs = startTimeMs
                        )
                    }

                }

                data class Widget(
                    val id: Int,
                    val title: String,
                    val image: ImageModel?,
                    val color: String?,
                    val textColor: String?
                ) : Data() {

                    fun toViewModel(): ViewModel.Widget.Data.Widget {
                        return ViewModel.Widget.Data.Widget(
                            id = id,
                            title = title,
                            image = image?.toViewModel(),
                            color = color,
                            textColor = textColor
                        )
                    }

                }

            }

            fun toViewModel(): ViewModel.Widget {
                return when (this) {
                    is ContentWidget.Circular -> ViewModel.Widget.ContentWidget.Circular(
                        id = id,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        title = title,
                        subtitle = subtitle,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                        hasMoreContents = content.size < totalContents
                    )
                    is ContentWidget.Banner -> ViewModel.Widget.ContentWidget.Banner(
                        id = id,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        title = title,
                        subtitle = subtitle,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                        hasMoreContents = content.size < totalContents
                    )
                    is ContentWidget.BannerV2 -> ViewModel.Widget.ContentWidget.BannerV2(
                        id = id,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        title = title,
                        subtitle = subtitle,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                        hasMoreContents = content.size < totalContents
                    )
                    is ContentWidget.GridV2 -> ViewModel.Widget.ContentWidget.GridV2(
                        id = id,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        title = title,
                        subtitle = subtitle,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                        hasMoreContents = content.size < totalContents
                    )
                    is ContentWidget.BannerLarge -> ViewModel.Widget.ContentWidget.BannerLarge(
                        id = id,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        title = title,
                        subtitle = subtitle,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                        hasMoreContents = content.size < totalContents
                    )
                    is ContentWidget.Progress -> ViewModel.Widget.ContentWidget.Progress(
                        id = id,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        title = title,
                        subtitle = subtitle,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                        hasMoreContents = content.size < totalContents
                    )
                    is ContentWidget.ProgressV2 -> ViewModel.Widget.ContentWidget.ProgressV2(
                        id = id,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        title = title,
                        subtitle = subtitle,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                        hasMoreContents = content.size < totalContents
                    )
                    is ContentWidget.Featured -> ViewModel.Widget.ContentWidget.Featured(
                        id = id,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        title = title,
                        subtitle = subtitle,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                        description = description,
                        rotateTimeSec = ViewModel.FEATURED_ROTATE_TIME_SEC,
                        hasMoreContents = content.size < totalContents
                    )
                    is ContentWidget.FeaturedV2 -> ViewModel.Widget.ContentWidget.FeaturedV2(
                        id = id,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        title = title,
                        subtitle = subtitle,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                        description = description,
                        rotateTimeSec = ViewModel.FEATURED_ROTATE_TIME_SEC,
                        hasMoreContents = content.size < totalContents
                    )
                    is ContentWidget.DirectoV2 -> ViewModel.Widget.ContentWidget.DirectoV2(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        hasMoreContents = content.size < totalContents,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                    )
                    is ContentWidget.Live -> ViewModel.Widget.ContentWidget.Live(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        hasMoreContents = content.size < totalContents,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                    )
                    is ContentWidget.LiveVertical -> ViewModel.Widget.ContentWidget.LiveVertical(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        hasMoreContents = content.size < totalContents,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                    )
                    is GroupWidget -> ViewModel.Widget.GroupWidget(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        action = trigger.toViewModel(),
                        widgets = widgets.map { it.toViewModel() },
                        hasMoreContents = widgets.size < totalContents,
                        textColor = textColor,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                    )
                    is ContentWidget.Poster -> ViewModel.Widget.ContentWidget.Poster(
                        id = id,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        title = title,
                        subtitle = subtitle,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                        hasMoreContents = content.size < totalContents
                    )
                    is ContentWidget.PosterV2 -> ViewModel.Widget.ContentWidget.PosterV2(
                        id = id,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        title = title,
                        subtitle = subtitle,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                        hasMoreContents = content.size < totalContents
                    )
                    is ContentWidget.News -> ViewModel.Widget.ContentWidget.News(
                        id = id,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        title = title,
                        subtitle = subtitle,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                        hasMoreContents = content.size < totalContents
                    )
                    is ContentWidget.Live2 -> ViewModel.Widget.ContentWidget.Live2(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        action = trigger.toViewModel(),
                        content = content.map { it.toViewModel() },
                        hasMoreContents = content.size < totalContents,
                        backgroundColor = backgroundColor,
                        backgroundImage = backgroundImage?.toViewModel(),
                        textColor = textColor,
                    )
                }
            }
        }
    }
    internal sealed class ApiResponse {
        @Serializable
        data class WidgetContentApiLoad(
            @Serializable(with = Widget.Companion.ContentListDeserializer::class)
            @SerialName(CONTENTS_PARAM)
            val contents: List<Data> = emptyList(),
            @SerialName(PAGE_PARAM)
            val page: Int? = null,
            @SerialName(LIMIT_PARAM)
            val limit: Int? = null,
            @SerialName(SORT_PARAM)
            val sort: String? = null,
            @SerialName(META_PARAM)
            val meta: Meta? = null,
            @SerialName(WIDGET_PARAM)
            val widget: Widget
        ) {
            companion object {
                const val CONTENTS_PARAM = "data"
                const val PAGE_PARAM = "page"
                const val LIMIT_PARAM = "limit"
                const val SORT_PARAM = "sort"
                const val META_PARAM = "meta"
                const val WIDGET_PARAM = "widget"
            }

            @Serializable
            data class Meta(
                @SerialName(FILTER_COUNT_PARAM)
                val filterCount: Int
            ) {
                companion object {
                    const val FILTER_COUNT_PARAM = "filter_count"
                }
            }

            fun toModel(language: String): Model.WidgetContentApiLoad {
                val parsedSort = if (sort != null) {
                    es.cinfo.tiivii.core.sorting.SortModel.Model.Sort.fromParam(sort)
                } else {
                    es.cinfo.tiivii.core.sorting.SortModel.Model.Sort.mostRecentFirst
                }
                return Model.WidgetContentApiLoad(
                    type = Model.WidgetType.parse(widget.type),
                    widget = widget.toModel(contents, language),
                    count = meta?.filterCount ?: contents.size,
                    page = page ?: 1,
                    limit = limit ?: contents.size,
                    sort = parsedSort
                )
            }
        }

        @Serializable
        data class WidgetListContentApiLoad(
            @SerialName(WIDGET_LIST_PARAM)
            val widgetList: List<WidgetContentApiLoad>,
        ) {
            companion object {
                const val WIDGET_LIST_PARAM = "data"
            }

            fun toModel(language: String): Model.WidgetListContentApiLoad {
                return Model.WidgetListContentApiLoad(
                    widgetList.map {
                        it.toModel(language)
                    })
            }
        }

        @Serializable
        data class Widget(
            @SerialName(ID_PARAM)
            val id: Int,
            @SerialName(TYPE_PARAM)
            val type: String,
            @SerialName(TITLE_PARAM)
            val title: String,
            @SerialName(SUBTITLE_PARAM)
            val subtitle: String?,
            @SerialName(DESCRIPTION_PARAM)
            val description: String? = null,
            @SerialName(TRIGGER_PARAM)
            val trigger: String,
            @SerialName(BG_COLOR_PARAM)
            val backgroundColor: String? = null,
            @SerialName(BG_IMAGE_PARAM)
            val backgroundImage: ImageApiResponse? = null,
            @SerialName(TEXT_COLOR_PARAM)
            val textColor: String? = null,
            @SerialName(CONTENTS_SHOWN_PARAM)
            val contentsShown: Int? = null,
            @SerialName(META_PARAM)
            // TODO: Remove nullable metadata property
            val metadata: Meta? = null,
            @SerialName(TRANSLATIONS_PARAM)
            @Serializable(with = TranslationModel.ApiResponse.Translations.TranslationsDeserializer::class)
            val translations: TranslationModel.ApiResponse.Translations?
        ) {

            companion object {
                const val ID_PARAM = "id"
                const val TYPE_PARAM = "type"
                const val TITLE_PARAM = "title"
                const val SUBTITLE_PARAM = "subtitle"
                const val DESCRIPTION_PARAM = "description"
                const val TRIGGER_PARAM = "trigger"
                const val BG_COLOR_PARAM = "backgroundcolor"
                const val TEXT_COLOR_PARAM = "textcolor"
                const val BG_IMAGE_PARAM = "backgroundimage"
                const val META_PARAM = "meta"
                const val CONTENTS_SHOWN_PARAM = "contents_shown"
                const val TRANSLATIONS_PARAM = "translations"

                object ContentListDeserializer
                    : JsonTransformingSerializer<List<Data>>(
                    ListSerializer(Data.serializer())
                ) {
                    // If response is not an array, then it is a single object that should be wrapped into the array
                    override fun transformDeserialize(element: JsonElement): JsonElement =
                        if (element !is JsonArray) JsonArray(listOf(element)) else element
                }

                @Serializable
                data class Meta(
                    @SerialName(FILTER_COUNT_PARAM)
                    val filterCount: Int
                ) {
                    companion object {
                        const val FILTER_COUNT_PARAM = "filter_count"
                    }
                }
            }

            @Serializable
            sealed class Data {

                abstract fun toModel(): Model.Widget.Data

                companion object {
                    const val WIDGET_NAME = "filter"
                }

                @Serializable
                data class Content(
                    @SerialName(ID_PARAM)
                    val id: Int,
                    @SerialName(TITLE_PARAM)
                    val title: String,
                    @SerialName(SUBTITLE_PARAM)
                    val subtitle: String? = null,
                    @SerialName(DESCRIPTION_PARAM)
                    val description: String? = null,
                    @SerialName(TYPE_PARAM)
                    val type: String,
                    @SerialName(CATEGORY_PARAM)
                    val category: Category,
                    @SerialName(SUBCATEGORY_PARAM)
                    val subcategory: Category? = null,
                    @SerialName(TAGS_PARAM)
                    val tags: List<String>,
                    @SerialName(EPISODE_PARAM)
                    val episode: Int?,
                    @SerialName(RATING_PARAM)
                    val rating: RatingModel.ApiResponse.AgeRating,
                    @SerialName(SCORE_PARAM)
                    val score: Float,
                    @SerialName(VOTES_PARAM)
                    val votes: Int,
                    @SerialName(START_TIME_EPOCH_MS_PARAM)
                    val startTimeEpochSec: Long? = null,
                    @SerialName(PUBLISH_DATE_PARAM)
                    val publishDate: String?,
                    @SerialName(POSTER_PARAM)
                    val poster: ImageApiResponse? = null,
                    @SerialName(BANNER_PARAM)
                    val banner: ImageApiResponse? = null,
                    @SerialName(BACKGROUND_PARAM)
                    val background: ImageApiResponse? = null,
                    @SerialName(BOOKMARKS_PARAM)
                    val bookmarks: List<JsonElement> = emptyList()
                ) : Data() {
                    companion object {
                        const val ID_PARAM = "id"
                        const val TITLE_PARAM = "title"
                        const val SUBTITLE_PARAM = "subtitle"
                        const val DESCRIPTION_PARAM = "description"
                        const val TYPE_PARAM = "type"
                        const val CATEGORY_PARAM = "category"
                        const val SUBCATEGORY_PARAM = "subcategory"
                        const val TAGS_PARAM = "tags"
                        const val EPISODE_PARAM = "episode"
                        const val RATING_PARAM = "rating"
                        const val SCORE_PARAM = "valoration"
                        const val VOTES_PARAM = "votes"
                        const val PUBLISH_DATE_PARAM = "publish_date"
                        const val POSTER_PARAM = "poster"
                        const val BANNER_PARAM = "banner"
                        const val BACKGROUND_PARAM = "background"
                        const val BOOKMARKS_PARAM = "bookmarks"
                        const val START_TIME_EPOCH_MS_PARAM = "start"
                    }

                    @Serializable
                    data class Category(
                        @SerialName(CODE_PARAM)
                        val code: String,
                    ) {
                        companion object {
                            const val CODE_PARAM = "code"
                        }
                    }

                    override fun toModel(): Model.Widget.Data.Content {
                        val startTimeMs = startTimeEpochSec?.times(1000)
                        val score = max(min(score, MAX_SCORE_VALUE), MIN_SCORE_VALUE)
                        return Model.Widget.Data.Content(
                            id = id,
                            title = title,
                            subtitle = subtitle,
                            description = description,
                            type = ContentTypeModel.Model.ContentType.parse(type),
                            category = category.code,
                            subcategory = subcategory?.code,
                            tags = tags,
                            episode = episode,
                            rating = rating.code,
                            score = score,
                            publishDate = publishDate,
                            background = background?.toModel(ImageType.BACKGROUND),
                            banner = banner?.toModel(ImageType.BANNER),
                            poster = poster?.toModel(ImageType.POSTER),
                            bookmarks = BookmarkModel.ApiResponse.Bookmarks.toModel(bookmarks),
                            startTimeMs = startTimeMs,
                            votes = votes
                        )
                    }
                }

                @Serializable
                @SerialName(WIDGET_NAME)
                data class Widget(
                    @SerialName(ID_PARAM)
                    val id: Int,
                    @SerialName(TITLE_PARAM)
                    val title: String,
                    @SerialName(IMAGE_PARAM)
                    val image: es.cinfo.tiivii.core.image.ApiResponse.Image?,
                    @SerialName(COLOR_PARAM)
                    val color: String?,
                    @SerialName(TEXT_COLOR_PARAM)
                    val textColor: String?
                ) : Data() {
                    companion object {
                        const val ID_PARAM = "id"
                        const val TITLE_PARAM = "title"
                        const val IMAGE_PARAM = "backgroundimage"
                        const val COLOR_PARAM = "backgroundcolor"
                        const val TEXT_COLOR_PARAM = "textcolor"
                    }

                    override fun toModel(): Model.Widget.Data.Widget {
                        val imageModule: ImageModule by diContainer.instance()
                        //TODO: Type of images in filters?
                        val imageModel = image?.let {
                            ImageModel(
                                image.id,
                                image.width,
                                image.height,
                                "${imageModule.baseEndpoint}${image.data.assetUrl}",
                                ImageModel.Type.BACKGROUND
                            )
                        }
                        return Model.Widget.Data.Widget(
                            id = id,
                            title = title,
                            image = imageModel,
                            color = color.parseAlphaHexOrNull(),
                            textColor = textColor.parseAlphaHexOrNull()
                        )
                    }
                }
            }

            fun toModel(content: List<Data>, language: String): Model.Widget {
                val imageModule: ImageModule by diContainer.instance()
                val imageModel = backgroundImage?.let {
                    // TODO: Image type of background widget image?
                    ImageModel(
                        it.id,
                        it.width,
                        it.height,
                        "${imageModule.baseEndpoint}${it.data.assetUrl}",
                        ImageModel.Type.BACKGROUND
                    )
                }
                val action = Model.WidgetAction.parse(trigger)
                fun parseAsDataContent(data: Data, widgetType: Model.WidgetType): Model.Widget.Data.Content {
                    return if (data is Data.Content) {
                        data.toModel()
                    } else {
                        throw SerializationException("Unexpected content type ${data::class.simpleName} for widget $widgetType")
                    }
                }
                fun parseAsDataWidget(data: Data, widgetType: Model.WidgetType): Model.Widget.Data.Widget {
                    return if (data is Data.Widget) {
                        data.toModel()
                    } else {
                        throw SerializationException("Unexpected content type ${data::class.simpleName} for widget $widgetType")
                    }
                }
                val title = translations?.find(TITLE_PARAM, language) ?: title
                val subtitle = translations?.find(SUBTITLE_PARAM, language) ?: subtitle
                val description = translations?.find(DESCRIPTION_PARAM, language) ?: description
                return when (val widgetType = Model.WidgetType.parse(type)) {
                    Model.WidgetType.CIRCULAR -> Model.Widget.ContentWidget.Circular(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.BANNER -> Model.Widget.ContentWidget.Banner(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.BANNER_V2 -> Model.Widget.ContentWidget.BannerV2(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.GRID_V2 -> Model.Widget.ContentWidget.GridV2(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.WIDGET_PROGRESS -> Model.Widget.ContentWidget.Progress(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.WIDGET_PROGRESS_V2 -> Model.Widget.ContentWidget.ProgressV2(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.FEATURED -> Model.Widget.ContentWidget.Featured(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        rotateTime = ViewModel.FEATURED_ROTATE_TIME_SEC,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.FEATURED_V2 -> Model.Widget.ContentWidget.FeaturedV2(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        rotateTime = ViewModel.FEATURED_ROTATE_TIME_SEC,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.BANNER_LARGE -> Model.Widget.ContentWidget.BannerLarge(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.DIRECTO_V2 -> Model.Widget.ContentWidget.DirectoV2(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.LIVE -> Model.Widget.ContentWidget.Live(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.LIVE_VERTICAL -> Model.Widget.ContentWidget.LiveVertical(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.FILTERS -> Model.Widget.GroupWidget(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        widgets = content.map { parseAsDataWidget(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.POSTER -> Model.Widget.ContentWidget.Poster(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.POSTER_V2 -> Model.Widget.ContentWidget.PosterV2(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.NEWS -> Model.Widget.ContentWidget.News(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                    Model.WidgetType.LIVE_2 -> Model.Widget.ContentWidget.Live2(
                        id = id,
                        title = title,
                        subtitle = subtitle,
                        description = description,
                        trigger = action,
                        backgroundColor = backgroundColor.parseAlphaHexOrNull(),
                        backgroundImage = imageModel,
                        textColor = textColor.parseAlphaHexOrNull(),
                        content = content.map { parseAsDataContent(it, widgetType) },
                        totalContents = metadata?.filterCount ?: content.size
                    )
                }
            }
        }
    }
}
